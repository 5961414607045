import React, { useEffect } from 'react';
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus} from "@azure/msal-browser";
import { useDispatch } from 'react-redux';


import { get } from './http';
import { setupAuth, handleLoginRedirect } from './auth/authConfig';
import { retrieveUser } from './redux/actions';

import Main from '@containers/Main';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance , accounts, inProgress} = useMsal();
  const activeAccount = useAccount();
  const dispatch = useDispatch();

  useEffect(() => {

    async function configure() {
      try {
        const configuration = await get('/api/app/v1/configuration');
        window.CONFIGURATION = configuration.app;
      } catch (e) {
        console.error('Unable to load configuration from server', e);
      }
    };

    configure();
  }, []);

  useEffect(() => {

    async function renewToken() {
      try {
      const token = await instance.acquireTokenSilent({ account: activeAccount });
      setupAuth(token.idToken);
      dispatch(retrieveUser(token));
      } catch (error) {
        console.error("Error silently acquiring token", error);
        handleLoginRedirect(instance);
      }
    }

    if (!activeAccount) instance.setActiveAccount(accounts[0]);
    if (inProgress == InteractionStatus.None && isAuthenticated) renewToken();
  }, [inProgress]);

  return (
      <Main />
  );
};

export default App;
