import React from 'react';
import { get } from './http';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, redirect } from 'react-router-dom';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import ErrorBoundary from '@containers/ErrorBoundary';
import 'react-toastify/dist/ReactToastify.min.css';

import { Buffer } from 'buffer';
import { v4 as uuidv4 } from 'uuid';

import App from './App';
import { getMsalConfig } from './auth/authConfig';
import store from './redux/store';
import theme from './theme';
import './styles/index.css';


const nonce = Buffer.from(uuidv4()).toString('base64');
const cache = createCache({
  key: 'prefix-key',
  nonce: nonce,
  insertionPoint: document.getElementById('emotion-insertion-point'),
  prepend: true,
});

const msalConfig = await getMsalConfig();
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <Provider store={store} >
          <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <App />
                </MsalAuthenticationTemplate>
                <ToastContainer style={{ width: "500px" }} />
              </ErrorBoundary>
            </ThemeProvider>
          </CacheProvider>
        </Provider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);