import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { AssessmentDetail } from '@components/AssessmentDetail';
import Navbar from "@components/Navbar";
import Header from '@components/Header';
import Passages from './Passages';
import SafetyCentreSelection from './SafetyCentreSelection';
import SafetyCentreConfig from './SafetyCentreConfig';
import SafetyCentres from './SafetyCentres';
import Search from './Search';
import TargetedVehicles from './TargetedVehicles';
import Settings from './Settings';
import FullPageLoader from '@components/Loader';

import {
  restoreSafetyCentreSelection,
  setClientConnection,
} from '@actions';
import { setSafetyCentreIdCookies } from '../client';
import SafetyCentreHistory from './SafetyCentreConfigHistory';
import { getGlobalParameters } from '@actions';
import { querySafetyCentre } from 'src/redux/actions';
import { handleLogoutRedirect } from 'src/auth/authConfig';
import {
  ENFORCEMENT_OFFICER,
  SYSTEM_ADMINISTRATOR,
  SYSTEM_MAINTAINER,
  TVL_ADMIN,
  CVSC_ADMIN
} from '../auth/roles.js';


function SetSafetyCentre({ match }) {
  console.log('setting id to ', match.params.scid);
  setSafetyCentreIdCookies(match.params.scid);
  return null;
}

const ProtectedLayout = ({ allowedGroups }) => {
  const userGroups = useSelector(state => state.user.groups);
  const hasAccess = allowedGroups.some(group => userGroups.includes(group));

  return hasAccess ? <Outlet /> : <Navigate to="/unauthorized" />;
};

const Main = () => {
  const { instance } = useMsal();
  const user = useSelector(state => state.user);

  const safetyCentre = useSelector(state =>
    state.configuration.selected === undefined
      ? undefined
      : state.configuration.selected &&
        state.configuration.safetyCentres[state.configuration.selected]
        ? state.configuration.safetyCentres[state.configuration.selected]
        : null
  );
  const selected = useSelector(state => state.configuration.selected);

  const dispatch = useDispatch();

  let clientInterval;
  let safetyCenterApproachStatusInterval;

  const [menuOpen, setMenu] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(restoreSafetyCentreSelection());
      dispatch(setClientConnection());
      dispatch(getGlobalParameters());
      // eslint-disable-next-line
      clientInterval = window.setInterval(setClientConnection(), 14500);
    }
    return () => {
      clientInterval && window.clearInterval(clientInterval);
    }
  }, [user]);

  useEffect(() => {
    // eslint-disable-next-line
    safetyCenterApproachStatusInterval = window.setInterval(querySafetyCentre(selected), 5000);
    return () => {
      safetyCenterApproachStatusInterval && window.clearInterval(safetyCenterApproachStatusInterval);
    }
  }, [safetyCentre, selected])

  const toggleMenu = () => setMenu(!menuOpen);
  const logout = () => handleLogoutRedirect(instance);
  const title = (safetyCentre && safetyCentre.name) || 'Vehicle Screening';

  return (
    <Box maxHeight="100%">
      <AuthenticatedTemplate>
        {!user ? <FullPageLoader open message="Waiting for user information" /> :
          (
            <>
              <Header className="sticky top-0" title={title} onMenuClick={toggleMenu} />
              <Navbar className="z-100" open={menuOpen} onMenuClick={toggleMenu} logout={logout} />

              {user.isEnforcementOfficer && !selected && (
                <SafetyCentreSelection />
              )}

              <Routes>

                <Route path="*" element={<FullPageLoader open disableSpinner message="Page not found please check URL or return to previous page" />} />
                <Route path="/oauth-authorized/azure" element={<Navigate to={'/'} replace={true} />} />
                <Route path="/unauthorized" element={<FullPageLoader open disableSpinner message="Page not found please check URL or return to previous page" />} />

                <Route path="/"
                  element={
                    (user.isEnforcementOfficer && !!selected) ?
                      <Navigate to={`/safety-centres/${selected}/passages`} replace={true} /> :
                      (!user.isEnforcementOfficer && user.isSystemMaintainer) ?
                        <Navigate to={`/safety-centres`} replace={true} /> :
                        null
                  } />

                <Route element={<ProtectedLayout allowedGroups={[SYSTEM_ADMINISTRATOR, SYSTEM_MAINTAINER]} />} >
                  <Route path="/safety-centres" element={<SafetyCentres />} />
                </Route>

                <Route element={<ProtectedLayout allowedGroups={[SYSTEM_ADMINISTRATOR, SYSTEM_MAINTAINER, ENFORCEMENT_OFFICER]} />} >
                  <Route path="safety-centres/:scid/passages" element={<Passages />} />
                  <Route path="/assessments" element={<Search />} />
                  <Route path="/assessments/:mcid" element={<AssessmentDetail hideCloseButton={true} renderHeader />} />
                </Route>

                <Route element={<ProtectedLayout allowedGroups={[SYSTEM_ADMINISTRATOR, CVSC_ADMIN]} />} >
                <Route path="/safety-centres/configure/:scid" element={<SetSafetyCentre />} />
                  <Route path="/safety-centres/:scid/config" element={<SafetyCentreConfig />} />
                  <Route path="/safety-centres/:scid/:approachId/history" element={<SafetyCentreHistory />} />
                </Route>

                <Route element={<ProtectedLayout allowedGroups={[SYSTEM_ADMINISTRATOR, SYSTEM_MAINTAINER, TVL_ADMIN]} />} >
                  <Route path="/targeted-vehicles" element={<TargetedVehicles />} />
                </Route>

                <Route element={<ProtectedLayout allowedGroups={[SYSTEM_ADMINISTRATOR]} />} >
                  <Route path="/settings" element={<Settings />} />
                </Route>

              </Routes>

            </>
          )}
      </AuthenticatedTemplate>
    </Box>
  );
}

export default Main;
