import * as api from '../services';
import store from '../store';
import {
  APPROACH_RECEIVED,
  CLEAR_VEHICLE_SEARCH_RESULTS,
  DELETE_TARGETED_VEHICLE,
  ERROR_CLEARED,
  ERROR_RAISED,
  EXECUTE_VEHICLE_SEARCH,
  PASSAGES_RECEIVED,
  RECEIVE_MEASURED_COMBINATION,
  RECEIVE_TARGETED_VEHICLES,
  RECEIVE_VEHICLE_SEARCH_RESULTS,
  SAFETY_CENTRES_RECEIVED,
  SAFETY_CENTRE_RECEIVED,
  SAFETY_CENTRE_SELECTED,
  SAFETY_CENTRE_HISTORY_RECEIVED,
  SAFETY_CENTRE_STATUS_REPORT_RECEIVED,
  USER_RECEIVED,
  GLOBAL_PARAMETERS_RECEIVED
} from '../action-types';

import { getClientId, retrieveSafetyCentreIdCookie, setSafetyCentreIdCookie } from '../../client';

export const KEY_SELECTED_SAFETY_CENTRE = 'selected-safety-centre';
export const KEY_CLIENT_ID = 'client-id';

export const raiseError = (title, message) => {
  return {
    type: ERROR_RAISED,
    title,
    message,
  };
};

export const clearError = () => {
  return {
    type: ERROR_CLEARED,
  };
};

// user
export const retrieveUser = (user) => async (dispatch) => {
  try {

    if (user === undefined) {
      throw new Error('user undefined');
    }

    return dispatch({
      type: USER_RECEIVED,
      user,
    });
  } catch (e) {
    // error loading user
    console.log('error retrieving user - redirecting to login', e);
  }
};

// if a SCID or selected SC is passed and the selected SC is not in the list, retrieve it
export const searchSafetyCentres = (selected) => async (dispatch) => {
  const result = await api.searchSafetyCentres();

  if (selected && !result.items[selected]) {
    const safetyCentre = await api.retrieveSafetyCentre(selected);
    result.items.unshift(safetyCentre);
  }

  return dispatch({
    type: SAFETY_CENTRES_RECEIVED,
    safetyCentres: result.items,
  });
};

export const restoreSafetyCentreSelection = () => async (dispatch) => {
  console.log('restoring selected safety centre');

  // check local storage for an existing selection
  const selected = retrieveSafetyCentreIdCookie();

  let safetyCentre = null;
  if (selected) {
    try {
      safetyCentre = await api.retrieveSafetyCentre(selected);
      dispatch(safetyCentreReceived(safetyCentre));
    } catch (e) {
      console.error(
        `Failed to load data for persisted Safety Centre ${selected}`
      );
      window.localStorage.removeItem(KEY_SELECTED_SAFETY_CENTRE);
    }
  }

  return dispatch({
    type: SAFETY_CENTRE_SELECTED,
    selected,
  });
};

// Used for monitoring the status of a safety centre approaches and auto updating 
// approach status across multiple windows and PC's
export const querySafetyCentre = (scid) => async () => {
  const state = store.getState();
  const dispatch = store.dispatch;

  if (scid) {
    try {
      let currentSafetyCentre = state.configuration.safetyCentres[scid];
      const safetyCentre = await api.retrieveSafetyCentre(scid);

      if (JSON.stringify(currentSafetyCentre) !== JSON.stringify(safetyCentre)) {
        dispatch(safetyCentreReceived(safetyCentre));
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export const retrieveSafetyCentre = (scid) => async (dispatch) => {
  try {
    const safetyCentre = await api.retrieveSafetyCentre(scid);
    await dispatch(safetyCentreReceived(safetyCentre));

    return dispatch({
      type: SAFETY_CENTRE_SELECTED,
      selected: safetyCentre.id,
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateSafetyCentre = (safetyCentre) => async (dispatch, getState) => {
  
  const user = getState().user;
  if (!user.isCvscAdmin) {
    return;
  }

  try {
    safetyCentre.updateRequest.approaches.forEach(approach => {
      approach.status = "OFF"; // Does not affect actual approach status but used as a best practice/fail safe
    });
    const updatedSafetyCentre = await api.updateSafetyCentre(safetyCentre);
    dispatch(safetyCentreReceived(updatedSafetyCentre));
  } catch (e) {
    console.error(e);
  }
};

export const safetyCentreReceived = (safetyCentre) => {
  return {
    type: SAFETY_CENTRE_RECEIVED,
    safetyCentre,
  };
};

export const getSafetyCentreHistory = (id) => async (dispatch) => {
  try {
    const safetyCentreHistory = await api.retrieveSafetyCentreHistory(id);
    await dispatch(safetyCentreHistoryReceived(safetyCentreHistory));
  } catch (error) {
    console.error(error);
  }
}

export const safetyCentreHistoryReceived = (safetyCentreHistory) => {
  return {
    type: SAFETY_CENTRE_HISTORY_RECEIVED,
    safetyCentreHistory,
  };
};

export const selectSafetyCentre = (id) => async (dispatch) => {
  // set local storage for an existing safety centre
  setSafetyCentreIdCookie(id);
  // trigger a restore
  dispatch(restoreSafetyCentreSelection());
};

export const setApproachStatus = (scid, aid, status, rule) => async (
  dispatch
) => {
  try {
    const approach = await api.setApproachStatus(scid, aid, status, rule);
    dispatch(approachReceived(scid, approach));
  } catch (e) {
    dispatch(raiseError('Unable to set approach status', e.message));
  }
};

export const setClientConnection = () => async () => {
  try {
    if (getClientId()) {
      console.log("Setting client connection")
      await api.setClientConnection();
    }
  } catch (e) {
    console.error('Unable to set client connection: ', e);
  }
};

export const setAllApproachStatuses = (scid, status, rule) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const safetyCentre = state.configuration.safetyCentres[scid];
  const failures = [];
  const successes = [];
  // sequentially update signs
  await safetyCentre.approaches.reduce(async (previous, a) => {
    await previous;
    return api
      .setApproachStatus(scid, a.id, status, rule)
      .then((a) => {
        successes.push(a);
      })
      .catch((e) => {
        // error already logged at http
        failures.push(e.message);
      });
  }, Promise.resolve());

  if (failures.length > 0) {
    dispatch(
      raiseError('Unable to set approach status', Array.from(new Set(failures)).join('\n'))
    );
  }

  if (safetyCentre.approaches.length === successes.length) {
    return dispatch(safetyCentreReceived({
      ...safetyCentre,
      approaches: successes
    }));
  } else {
    return successes.map((a) => {
      return dispatch(approachReceived(scid, a));
    });
  }
};

export const approachReceived = (scid, approach) => {
  return {
    type: APPROACH_RECEIVED,
    scid,
    approach,
  };
};

// passages

export const searchPassages = (scid, cursor) => async (dispatch) => {
  try {
    const result = await api.searchPassages(scid, cursor);
    return dispatch({
      type: PASSAGES_RECEIVED,
      scid,
      cursor,
      passages: result.items,
    });
  } catch (e) {
    console.log('Unable to retrieve passages: ', e);
  }
};

// vehicle search
export const clearVehicleSearchResults = () => async (dispatch) => {
  return dispatch({
    type: CLEAR_VEHICLE_SEARCH_RESULTS,
  });
};

export const searchMeasuredCombinations = (
  scid,
  approachId,
  plate,
  from,
  to,
  assessmentType,
  cursor,
  clear
) => async (dispatch) => {
  try {
    if (clear) {
      await dispatch(clearVehicleSearchResults());
    }

    dispatch({
      type: EXECUTE_VEHICLE_SEARCH,
      query: {
        scid,
        approachId,
        plate,
        from,
        to,
        assessmentType
      },
    });

    const result = await api.searchMeasuredCombinations(
      scid === "All" ? "" : scid,
      approachId,
      plate,
      from,
      to,
      assessmentType,
      cursor
    );
    return dispatch({
      type: RECEIVE_VEHICLE_SEARCH_RESULTS,
      scid,
      cursor: result.cursor,
      items: result.items,
    });
  } catch (e) {
    console.log('Unable to search: ', e);
  }
};

export const retrieveMeasuredCombination = (id) => async (dispatch) => {
  try {
    const measuredCombination = await api.retrieveMeasuredCombination(id);
    return dispatch({
      type: RECEIVE_MEASURED_COMBINATION,
      measuredCombination,
    });
  } catch (e) {
    console.log('Unable to search: ', e);
  }
};

// safety centre status report

export const getSafetyCentreStatusReport = (scid) => async (dispatch) => {
  try {
    const result = await api.getSafetyCentreStatusReport(scid);
    return dispatch({
      type: SAFETY_CENTRE_STATUS_REPORT_RECEIVED,
      scid,
      safetyCentreStatusReport: result,
    });
  } catch (e) {
    console.log('Unable to retrieve status: ', e);
  }
};

// Targeted Vehicles

export const searchTargetedVehicles = () => async (dispatch) => {
  return dispatch({
    type: RECEIVE_TARGETED_VEHICLES,
    targetedVehicles: await api.searchTargetedVehicles(),
  });
};

export const saveTargetedVehicle = (tv) => async (dispatch, getState) => {
  const user = getState().user;
  // eslint-disable-next-line
  if (user.isEnforcementOfficer && !user.isTvlAdmin && !tv.category == "recall") {
    return;
  }

  await api.saveTargetedVehicle(tv);
  
  if (user.isTvlAdmin) {
    return dispatch(searchTargetedVehicles());
  }
};

export const updateTargetedVehicles = (tvs) => async (dispatch) => {
  await api.updateTargetedVehicles(tvs);
  return dispatch(searchTargetedVehicles());
};

export const deleteTargetedVehicle = (id) => async (dispatch) => {
  await api.deleteTargetedVehicle(id);
  return dispatch({
    type: DELETE_TARGETED_VEHICLE,
    id,
  });
};

export const deleteTargetedVehicles = (ids) => async (dispatch) => {
  try {
    const promises = ids.map((id) => {
      return api.deleteTargetedVehicle(id);
    });
    await Promise.all(promises);
  } catch (e) {
    raiseError('Error', 'there was a problem deleting some data');
  }

  return dispatch(searchTargetedVehicles());
};

// global parameters
export const getGlobalParameters = () => async (dispatch) => {
  try {
    const result = await api.getGlobalParameters();
    return dispatch({
      type: GLOBAL_PARAMETERS_RECEIVED,
      globalParameters: result,
    });
  } catch (e) {
    console.log('Unable to retrieve global parameters: ', e);
  }
};

export const updateGlobalParameter = (values) => async (dispatch, getState) => {
  try {
    await api.updateGlobalParameter(values);

    const globalParametersState = getState().globalParameters.targetedVehicle;
    const index = globalParametersState.findIndex((param) => param.key === values.key);
    const updatedGlobalParameters = [
      ...globalParametersState.slice(0, index),
      values,
      ...globalParametersState.slice(index + 1),
    ];

    return dispatch({
      type: GLOBAL_PARAMETERS_RECEIVED,
      globalParameters: updatedGlobalParameters,
    });
  } catch (e) {
    console.log('Unable to update global parameters: ', e);
  }
};