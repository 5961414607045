import axios from 'axios';
import { getClientId } from '../client';
import { get } from '../http'

export const getMsalConfig = async () => {
  try {
    const configuration = await get('/api/app/v1/configuration');
    return {
      auth: {
        clientId: configuration.entraID.clientId,
        authority: configuration.entraID.issuer,
        redirectUri: configuration.entraID.redirectUri,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      }
    }
  } catch (e) {
    console.error('Unable to load authentication configuration from server', e);
  }
};

export const setupAuth = async (token) => {
  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    config.headers['client-id'] = getClientId();
    return config;
  }, function (error) {
    console.log("Error in request interceptor: ", error);
    return Promise.reject(error);
  });
};

export function handleLoginRedirect(msalInstance) {
  msalInstance.loginRedirect().catch((error) => console.log(error));
};
export function handleLogoutRedirect(msalInstance) {
  msalInstance.logoutRedirect().catch((error) => console.log(error));
};

